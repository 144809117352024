.Admin {
  min-height: calc(100vh - 16px);
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 15px;
}

.AdminInfoText {
  color: white;
  margin-left: 15px;
  margin-right: 15px;
}

.AnswerGroupTitle {
  font-size: 20px;
  color: white;
  margin-bottom: 10px;
}

.ResultsByArticle {
  margin-top: 10px;
  font-size: 16px;
  color: white;
}

.ArticleResults {
  margin-top: 10px;
  margin-bottom: 25px;
}

.ArticleResultsTitle {
  color: white;
  font-size: 12px;
}
