.AdminCard {
  display: flex;
  flex-direction: column;
  align-self: center;
  background-color: white;
  margin-right: 15px;
  margin-left: 15px;
  margin-top: 15px;
  margin-bottom: 5px;
  border-radius: 6px;
  overflow-x: hidden;
  user-select: none;
  cursor: pointer;
  padding-bottom: 16px;
}
