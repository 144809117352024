.Subject {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 9px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  height: 24px;
  background-color: #1ab4d7;
  border-radius: 100px 100px 100px 0;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 21px;
  margin-top: -12px;
  width: fit-content;
}
