.Card {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-self: center;
  background-color: white;
  margin-right: 30px;
  margin-left: 30px;
  margin-top: 35px;
  border-radius: 6px;
  min-height: 350px;
  max-height: 350px;
  min-width: 540px;
  overflow-x: hidden;
  top: 0;
  user-select: none;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .Card {
    min-width: calc(100vw - 60px);
  }
}

.articleImage {
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 14px;
  max-width: 100%;
  max-height: 150px;
  object-fit: cover;
  height: auto;
}

.articleTitle {
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 16px;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-family: 'Open Sans Condensed';
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  overflow: hidden;
}

.articleLead {
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 14px;
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #000000;
  overflow: scroll;
}
