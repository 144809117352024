.controlResultText {
  min-height: calc(100vh - 160px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-family: 'Open Sans Condensed';
}
