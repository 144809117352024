.Swipe {
  min-height: calc(100vh - 16px);
  display: flex;
  flex-direction: column;
}

.progress {
  height: 7px;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
}

.title {
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 20px;
  font-size: 22px;
  text-align: center;
  font-weight: bold;
  font-style: normal;
  color: white;
  font-family: 'Open Sans Condensed';
}
