.Landing {
  min-height: calc(100vh - 16px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.Logo {
  object-fit: contain;
  max-width: 50%;
}

.LogoText {
  color: white;
  font-size: 16px;
  margin-top: 15px;
  font-weight: bold;
  font-style: normal;
  font-family: 'Open Sans Condensed';
}

.AnswerIdInput {
  height: 44px;
  text-align: center;
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-family: 'Open Sans Condensed';
  width: 196px;
  border-radius: 25px;
  background-color: #ffffff;
  border: none;
  margin-top: 30px;
}

.SubmitButton {
  margin-top: 11px;
  width: 196px;
  height: 44px;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-family: 'Open Sans Condensed';
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 25px;
  border: 0;
  background-color: #7417ff;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.SubmitButton:active {
  opacity: 0.6;
}
