.ResultCard {
  display: flex;
  flex-direction: row;
  margin-bottom: 35px;
  position: relative;
  align-self: flex-start;
}

.ResultImage {
  object-fit: cover;
  width: 80px;
  height: 80px;
  border-radius: 4px;
  margin-left: 34px;
  position: relative;
  min-height: 80px;
  min-width: 80px;
}

.ResultContentWrapper {
  display: flex;
  flex-direction: column;
}

.ResultTitle {
  margin-right: 24px;
  margin-left: 16px;
  font-family: 'Open Sans Condensed';
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.Thumbs {
  margin-left: 22px;
  position: absolute;
  top: -12px;
  left: 0;
  object-fit: contain;
  width: 25px;
  height: 25px;
}

.ResultInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #7417ff;
  border-radius: 4px;
  margin-left: 16px;
  margin-right: 24px;
  margin-top: 13px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  min-width: calc(100vw - 170px);
}

@media screen and (min-width: 600px) {
  .ResultInfo {
    min-width: 450px;
  }
}

.ResultCategoryWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ResultInfoText {
  color: white;
  font-family: 'Open Sans Condensed';
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.AnswerImg {
  height: 17px;
  width: 17px;
  object-fit: contain;
  margin-left: 5px;
}
