.ButtonImage {
  height: 50px;
  width: 50px;
}

.ButtonWrapper {
  padding: 0;
  margin-right: 33px;
  margin-left: 33px;
  height: 50px;
  width: 50px;
  background-color: transparent;
  border: 0;
}

.ButtonWrapper:active {
  opacity: 0.6;
}
