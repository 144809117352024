body {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  background-image: linear-gradient(150deg, #5d50d8, #c86dd7);
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body {
  overflow-x: hidden;
}

.PreventOverflow {
  overflow-x: hidden;
}
