.Results {
  min-height: calc(100vh - 16px);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
}

.Confetti {
  object-fit: contain;
  max-width: 30%;
  margin-top: 45px;
  animation: jelly 5s infinite;
}

.ScoreNumber {
  font-family: 'Open Sans Condensed';
  font-size: 60px;
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffeed1;
  margin-top: 20px;
}

.CopyText {
  font-family: 'Open Sans Condensed';
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.CopyTextSmall {
  margin-top: 42px;
  margin-bottom: 28px;
  font-family: 'Open Sans Condensed';
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

@keyframes jelly {
  0%,
  20%,
  100% {
    transform: scale(1, 1);
  }
  5% {
    transform: scale(0.9, 1.1);
  }
  10% {
    transform: scale(1.1, 0.9);
  }
  15% {
    transform: scale(0.95, 1.05);
  }
}
